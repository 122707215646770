
let codeset = [];
let lineset = [];
let listset = [];
let mode = null;

function resetMode() {
	return {
		table: 0,
		trow: 0,
		tcell: 0,
		list: 0,
		box: 0,
		quote: 0,
	};
}
function reset() {
	codeset = [];
	lineset = [];
	listset = [];
	mode = resetMode();
}

function escapeTag(s) {
	return s.replaceAll('<', '&lt;').replaceAll('>', '&gt;')
}

function findCodes(s) {
	//return s.replace(new RegExp('\\[%(.+?)%\\]', 'msg'), (match, p1) => {
	return s.replace(/\[%(.+?)%\]/msg, (match, p1) => {
		const ret = `%%CODE${codeset.length}%%`;
		codeset.push(p1);
		return ret;
	});
}
function replaceCodes(s) {
	//return s.replace(new RegExp('%%CODE(\\d+)%%', 'msg'), (match, p1) => {
	return s.replace(/%%CODE(\d+)%%/msg, (match, p1) => {
		const n = Number(p1);
		const codelet = codeset[n].replaceAll('\t', '&nbsp; &nbsp; ')
			.replace(/( ( )+)/g, (match, p1) => '&nbsp;'.repeat(p1.length));
		return `<pre class="code">${codelet}</pre>`;
	});
}

function isBeginOfTable(line) {
	return /^\/{4}/.test(line);
}
function openTable(line) {
	mode.table = 1;
	lineset.push('<table>');
}
function closeTable() {
	mode.table = 0;
	lineset.push('</table>');
}
function isBeginOfTrow(line) {
	return /^ {2}\/{3}/.test(line);
}
function openTrow(line) {
	const attr = line.replace('  ///', '').trim();
	mode.trow = 1;
	lineset.push(attr ? `<tr ${attr}>` : '<tr>');
}
function closeTrow() {
	mode.trow = 0;
	lineset.push('</tr>');
}
function isBeginOfTcell(line) {
	return /^ {4}\/{2}/.test(line);
}
function openTcell(line) {
	let text = line.replace('    //', '').trim();
	const re = /\[\[(.+?)\]\]/;
	const match = re.exec(text);
	if (match) text = text.replace(re, '').trim();
	const attr = (match && match[1]) || '';
	mode.tcell = 1;
	lineset.push(attr ? `<td ${attr}>` : '<td>');
	treatTcellBody(text);
}
function closeTcell() {
	mode.tcell = 0;
	lineset.push('</td>');
}
function isTcellBody(line) {
	return /^ {7}/.test(line);
}
function treatTcellBody(line) {
	const text  = line.trim().replace(/ \\\\$/, '').replace(/ ?\\$/, '<br>');
	lineset.push(text);
	console.log(`treatTcellBody::${line}::${text}`)
}

const LIST_TAGS = {'*':'ul', '#':'ol'};
function isListItem(line) {
	return /^(  )*[*#] /.test(line);
}
function isListItemBody(line) {
	return /^(  )+/.test(line);
}
function openListItem(line) {
	const match = /^((  )*)([*#]) (.*)$/.exec(line);
	const depth = match[1].length / 2;
	const type = LIST_TAGS[match[3]];
	const text = match[4];
	if (!mode.list || walkingList().depth < depth) {
		openList(depth, type);
	} else {
		while (walkingList().depth > depth) {
			closeListItem();
			closeList();
		}
		closeListItem();
	}
	lineset.push('<li>');
	listItemBody(text);
}
function closeListItem() {
	lineset.push(lineset.pop().replace(/<br>$/, ''));
	lineset.push('</li>');
}
function listItemBody(line) {
	const match = /^((  )+)([^ ].*)$/.exec(line);
	if (match) {
		const depth = match[1].length / 2 - 1;
		const text = match[3];
		while (walkingList().depth > depth) {
			closeListItem();
			closeList();
		}
		line = text;
	}
	line = line.trim();
	line = trimNoLineBreakOrBreak(line);
	lineset.push(line);
}
function openList(depth, type) {
	mode.list = 1;
	listset.push({depth, type});
	lineset.push(`<${type}>`);
}
function closeList() {
	const type = listset.pop().type;
	(listset.length === 0) && (mode.list = 0);
	lineset.push(`</${type}>`);
}
function walkingList() {
	return listset.length ? listset.slice(-1)[0] : null;
}

function isDivider(line) {
	return /^={4,}$/.test(line);
}
function _divider(s) {
	return s.replace(/={4,}\n/g, '<hr />\n');
}
function divider(line) {
	lineset.push('<hr />');
}

function isHeading(line) {
	return /^#{1,3} .+$/.test(line);
}
function _heading(s) {
	return s.replace(/(#{1,3}) (.+)\n/g, (match, p1, p2) => {
		const size = 4 - p1.length;
		const text = p2.trim();
		return `<h${size}>${text}</h${size}>\n`;
	});
}
function heading(line) {
	line = line.replace(/^(#{1,3}) (.+)$/, (match, p1, p2) => {
		const size = 4 - p1.length;
		const text = p2.trim();
		return `<h${size}>${text}</h${size}>`;
	});
	lineset.push(line);
}

function isCenter(line) {
	return /^{&gt;\|/.test(line) && /\|&lt;}$/.test(line);
}
function center(line) {
	line = line.replace(/^{&gt;\|/, '<div class="c">').replace(/\|&lt;}$/, '</div>');
	lineset.push(line);
}

function isBeginOfBox(line) {
	return /^{~/.test(line);
}
function openBox(line) {
	mode.box = 1;
	line = line.replace(/^{~/, '<div class="box">');
	if (isEndOfBox(line)) {
		mode.box = 0;
		line = line.replace(/~}$/, '</div>');
	} else if ('<div class="box">' === line.trim()) {
		line = line.trim();
	} else {
		line = trimNoLineBreakOrBreak(line);
	}
	lineset.push(line);
}
function isEndOfBox(line) {
	return /~}$/.test(line);
}
function closeBox(line) {
	mode.box = 0;
	line = line.replace(/~}$/, '</div>');
	if ('</div>' === line) { //if (/^~}$/.test(line)) {
		lineset.push(lineset.pop().replace(/<br>$/, ''));
	}
	lineset.push(line);
}
function boxBody(line) {
	treatLine(line);
}

function isBeginOfQuote(line) {
	//return /^{"/.test(line);
	return /{"/.test(line);
}
function openQuote(line) {
	mode.quote = 1;
	//line = line.replace(/^{"/, '<blockquote>');
	line = line.replace(/{"/, '<blockquote>');
	if (isEndOfQuote(line)) {
		mode.quote = 0;
		line = line.replace(/"}$/, '</blockquote>');
	} else if ('<blockquote>' === line.trim()) {
		line = line.trim();
	} else {
		line = trimNoLineBreakOrBreak(line);
	}
	lineset.push(line);
}
function isEndOfQuote(line) {
	return /"}$/.test(line);
}
function closeQuote(line) {
	mode.quote = 0;
	line = line.replace(/"}$/, '</blockquote>');
	if ('</blockquote>' === line) { //if (/^"}$/.test(line)) {
		lineset.push(lineset.pop().replace(/<br>$/, ''));
	}
	lineset.push(line);
}
function quoteBody(line) {
	treatLine(line);
}


function isNoLineBreak(line) {
	return (
		//isDivider(line) ||
		//isHeading(line) ||
		line.endsWith('\\')
	);
}
function trimNoLineBreakOrBreak(line) {
	if (isNoLineBreak(line)) {
		return line.replace(/\\$/, '').trim();
	} else {
		return line + '<br>';
	}
}
function treatLine(line) {
	line = line.replace(/^((  )+)/, (match) => match.replaceAll('  ', '&nbsp; '))
	line = trimNoLineBreakOrBreak(line);
	lineset.push(line);
}

function intLink(s) {
	return s.replace(/\[#(.+?)#\]/msg, (match, p1) => {
		const a = p1.split('=&gt;');
		const title = a[0].trim()
		const title_ = title.replaceAll(' ', '_');
		const text = (a[1] && a[1].trim()) || title;
		return [
			`<a href="/?title=${title_}" data-title="${title}" class="int">${text}</a>`,
			`<a href="/?title=${title_}" class="bi bi-box-arrow-up-right" target="_blank"></a>`,
		].join(' ');
	});
}
function extLink(s) {
	return s.replace(/\[@(.+?)@\]/msg, (match, p1) => {
		let a = p1.trim().split(' ');
		if (p1.includes('=&gt;')) {
			a = p1.split('=&gt;');
		}
		const href = a[0].trim();
		const text = (a.length > 1 && a.slice(1).join(' ').trim()) || href;
		if (/\[x:img\].+\[\/x:img\]/.test(text)) {
			return `<a href="${href}" target="_blank">${text}</a>`;
		}
		return `<a href="${href}" class="ext bi bi-link-45deg" target="_blank">${text}</a>`;
	});
}

function convert(s) {
	s = intLink(s);
	s = extLink(s);
	return s
		.replaceAll('\t', '&nbsp; ')
		.replace(/^((  )+)/, (match) => match.replaceAll('  ', '&nbsp; '))
		.replaceAll('[x:i]', '<span class="xi">')
		.replaceAll('[/x:i]', '</span>')
		.replaceAll('[x:b]', '<span class="xb">')
		.replaceAll('[/x:b]', '</span>')
		.replaceAll('[x:u]', '<span class="xu"><span>')
		.replaceAll('[/x:u]', '</span></span>')
		.replaceAll('[x:s]', '<span class="xs"><span>')
		.replaceAll('[/x:s]', '</span></span>')
		.replaceAll('[x:sub]', '<sub>')
		.replaceAll('[/x:sub]', '</sub>')
		.replaceAll('[x:sup]', '<sup>')
		.replaceAll('[/x:sup]', '</sup>')
		.replace(/\[x:st(:(.+?))?\](.+?)\[\/x:st\]/g, (match, p1, p2, p3) => {
			return (p2 ? `<span ${p2}>` : '<span>') + `${p3}</span>`;
		})
		.replaceAll('[x:img]', '<img src="')
		.replaceAll('[/x:img]', '" class="r">')
		.replaceAll('{/', '<span class="xi">')
		.replaceAll('/}', '</span>')
		.replaceAll('{*', '<span class="xb">')
		.replaceAll('*}', '</span>')
		.replaceAll('{_', '<span class="xu"><span>')
		.replaceAll('_}', '</span></span>')
		.replaceAll('{=', '<span class="xs"><span>')
		.replaceAll('=}', '</span></span>')
		.replaceAll('{.', '<span class="xd">')
		.replaceAll('.}', '</span>')
		.replaceAll('{-', '<sub>')
		.replaceAll('-}', '</sub>')
		.replaceAll('{+', '<sup>')
		.replaceAll('+}', '</sup>')
		.replaceAll('{`', '<code>')
		.replaceAll('`}', '</code>')
		.replaceAll('{&gt;&gt;', '<span class="r">')
		.replaceAll('&gt;&gt;}', '</span>')
		.replace(/( ( )+)/g, (match, p1) => '&nbsp;'.repeat(p1.length))
}

export function interprete(s) {
	reset();
	s = escapeTag(s);
	s = findCodes(s);

	// line by line
	let lines = s.split(s.includes('\r\n') ? '\r\n' : '\n');
	lines.forEach((line, index) => {
		//console.log(index, line);
		
		// table
		if (mode.table) {
			if (mode.tcell && isBeginOfTrow(line)) {
				closeTcell();
				closeTrow();
				openTrow(line);
				return;
			}
			if (mode.tcell && isBeginOfTcell(line)) {
				closeTcell();
				openTcell(line);
				return;
			}
			if (mode.tcell && isTcellBody(line)) {
				treatTcellBody(line);
				return;
			}
			if (mode.trow && isBeginOfTcell(line)) {
				openTcell(line);
				return;
			}
			if (mode.table && isBeginOfTrow(line)) {
				openTrow(line);
				return;
			}
			if (mode.tcell) {
				closeTcell();
			}
			if (mode.trow) {
				closeTrow();
			}
			if (mode.table) {
				closeTable();
			}
		} else if (isBeginOfTable(line)) {
			openTable(line);
			return;
		}

		// list
		if (mode.list) {
			if (isListItem(line)) {
				openListItem(line);
				return;
			}
			if (isListItemBody(line)) {
				listItemBody(line);
				return;
			}
			while (listset.length) {
				closeListItem();
				closeList();
			}
		} else if (isListItem(line)) {
			openListItem(line);
			return;
		}

		// box
		if (mode.box) {
			if (isEndOfBox(line)) {
				closeBox(line);
			} else {
				boxBody(line);
			}
			return;
		} else if (isBeginOfBox(line)) {
			openBox(line);
			return;
		}

		// quote
		if (mode.quote) {
			if (isEndOfQuote(line)) {
				closeQuote(line);
			} else {
				quoteBody(line);
			}
			return;
		} else if (isBeginOfQuote(line)) {
			openQuote(line);
			return;
		}

		// divider
		if (isDivider(line)) {
			divider(line);
			return;
		}
		// heading
		if (isHeading(line)) {
			heading(line);
			return;
		}
		// center
		if (isCenter(line)) {
			center(line);
			return;
		}

		// normal
		treatLine(line);
	});

	if (mode.table) {
		mode.tcell && closeTcell();
		mode.trow && closeTrow();
		mode.table && closeTable();
	}
	if (mode.list) {
		while (listset.length) {
			closeListItem();
			closeList();
		}
	}

	s = lineset.join('\n');
	s = replaceCodes(s);
	s = convert(s);
	return s;
}
