<script>
import {createEventDispatcher} from 'svelte';

const dispatch = createEventDispatcher();

export let sublist;

const findId = (id) => {
	dispatch('command', {action:'id', param:id});
}
const close = () => dispatch('close');
</script>

<div id="sublist">
	<div class="clearfix">
		<button class="close" on:click={close}>Close <i class="bi bi-x-square resizefont"></i></button>
	</div>
	<ul>
	{#each sublist as subnote, index}
		<li><a href={`/?id=${subnote.id}`} class="int" on:click|preventDefault={() => findId(subnote.id)}>{ subnote.title }</a>
		<a href={`/?id=${subnote.id}`} class="bi bi-box-arrow-up-right" rel="noreferrer" target="_blank"></a></li>
	{/each}
	</ul>
</div>

<style>
#sublist {
	position: absolute;
	width: 40%;
	min-width: 360px; /* 60em; */ /* 600px */
	max-height: 60%;
	bottom: 3rem;
	left: 50%;
	transform: translate(-50%, 0);
	/* margin-left: auto; margin-right: auto; */

	background-color: #000;
	border: 1px dotted #888;
	padding: 0.5em;
	box-sizing: border-box;
}

.close-btn {
	border: none;
	float: right;
}
</style>
	