
const BASE_URL = "https://nada.nottoowell.net";

let API_KEY = "";

async function _teleport(path, post) {
	const url = BASE_URL + path;
	const headers = {'X-API-KEY': API_KEY};
	const opt = {mode: "cors", headers};
	if (post) {
		Object.assign(headers, {
			"Content-Type": "application/json",
		});
		Object.assign(opt, {
			method: "post",
			body: post,
		});
	}
	const res = await fetch(url, opt);
	console.log(res);
	if (res.ok) {
		try {
			//const json = await res.json();
			const json = await res.text();
			console.log(json);
			try {
				const data = JSON.parse(json);
				console.log(data);
				return data;
			} catch (err) {
				console.log('fail to parse data :', err);
				const data = decodeURIComponent(json);
				console.log(data);
			}
		} catch (ex) {
			console.log('fail to read text :', ex);
		}
	} else {
		console.log('not ok');
		try {
			const json = await res.text();
			try {
				const error = JSON.parse(json);
				console.log(error.msg);
				return error;
			} catch (err) {
				console.log('fail to parse error :', err);
			}
		} catch (ex) {
			console.log('fail to read text :', ex);
		}
	}
}

const idlenote = {
	init(apiKey) {
		API_KEY = apiKey;
	},
	async home() {
		let note = await _teleport("/idle/home");
		return note;
	},
	async save(doc) {
		let note;
		if (doc.id) {
			note = await _teleport("/idle/update", JSON.stringify(doc));
		} else {
			note = await _teleport("/idle/create", JSON.stringify(doc));
		}
		return note;
	},
	async destroy(doc) {
		let note;
		if (doc.id) {
			note = await _teleport("/idle/delete", JSON.stringify(doc));
		}
		return note;
	},
	async read(noteId=0, title=null) {
		let note;
		if (noteId) {
			note = await _teleport("/idle/note/" + noteId);
		} else if (title) {
			note = await _teleport("/idle/open", JSON.stringify({title}));
		}
		return note;
	},
	async find(search=null, tag=null) {
		let noteList = [];
		if (search) {
			noteList = await _teleport("/idle/find", JSON.stringify({search}));
		} else if (tag) {
			noteList = await _teleport("/idle/tag", JSON.stringify({tag}));
		}
		return noteList;
	},
	async subnote(noteId) {
		let noteList = [];
		if (noteId) {
			noteList = await _teleport("/idle/sub/" + noteId);
		}
		return noteList;
	},
	async allnote() {
		let noteList = [];
		noteList = await _teleport("/idle/list");
		return noteList;
	},
};

export {
	idlenote
};
