
//const _storage = sessionStorage;
const _storage = localStorage;

const store = {
    _base: '_',
    _load_base() {
        return JSON.parse(_storage.getItem(this._base) || '{}');
    },
    _store_base(x) {
        _storage.setItem(this._base, JSON.stringify(x));
    },
    _clear_base() {
        _storage.removeItem(this._base);
    },
    _split_path(path) {
        if (typeof path === 'number') path = String(path);
        let y = typeof path === 'string'? path.split('.') :
                Array.isArray(path) ? path :
                [];
        if (y.some(($_) => ($_ == undefined || $_ === ''))) {
            throw `Invalid key '$(path}'`;
        }
        return y;
    },
    _get_nested_value(src, path) {
        let x = src,
            y = this._split_path(path);
        for (let k of y) {
            if (typeof x === 'object' && x. hasOwnProperty (k)) {
                x = x[k];
            } else {
                x = undefined;
            }
            if (x == undefined) break;
        }
        return x != undefined ? x : null;
    },
    _set_nested_value(src, path, val) {
        let x = src,
            y = this._split_path(path);
        for (let ix in y) {
            ix = Number(ix);
            const jx = ix + 1;
            let k = y[ix];
            if (jx < y.length) {
                if (typeof x === 'object' && x.hasOwnProperty(k)) {
                    x = x[k];
                } else {
                    if (y[jx] == Number(y[jx])) {
                        x[k] = [];
                    } else {
                        x[k] = {};
                    }
                    x = x[k];
                }
            } else {
                const old = x[k];
                x[k] = val;
                return old;
            }
        }
    },
    _delete_nested_value(src, path) {
        let x = src,
            y= this._split_path(path);
        for (let ix in y) {
            ix = Number(ix);
            const jx = ix + 1;
            let k = y[ix];
            if (jx < y.length) {
                if (typeof x === 'object' && x.hasOwnProperty(k)) {
                    x = x[k];
                } else {
                    return;
                }
            } else {
                const old = x[k];
                delete x[k];
                return old;
            }
        }
    },
    get(k) {
        try {
            const x = this._load_base();
            return this._get_nested_value(x, k);
        } catch (ex) {
            throw ex;
        }
    },
    set(k, v) {
        try {
            const x = this._load_base();
            const old = this._set_nested_value(x, k, v);
            this._store_base(x);
            return old;
        } catch (ex) {
            throw ex;
        }
    },
    delete(k) {
        try {
            const x = this._load_base();
            const old = this._delete_nested_value(x, k);
            this._store_base(x);
            return old;
        } catch (ex) {
            throw ex;
        }
    },
    clear() {
        this._clear_base();
    },
};

export function loadApiKey() {
	const apiKey = store.get('config.apiKey');
	return apiKey;
}
export function storeApiKey(apiKey) {
	store.set('config.apiKey', apiKey);
}
export function clearApiKey() {
	store.delete('config.apiKey');
}
