<script>
	import {createEventDispatcher, onMount, tick} from 'svelte';

	const dispatch = createEventDispatcher();

	export let note;
	export let edit_content;
	export let modified;

	let command = '';

	const actionMapping = {
		go: (param) =>
			param === '/' ? 'home' :
			param === '..' ? 'parent' :
			'',
		qi: () => 'id',
		qn: () => 'title',
		qs: () => 'search',
		qt: () => 'tag',
		ed: () => 'edit',
		ow: () => 'update',
		dc: () => 'discard',
		tl: () => 'tags',
		sl: () => 'sub',
		ns: () => 'newsub',
		mv: () => 'move',
		rm: () => 'destroy',
		st: () => 'store',
		re: () => 'reload',
		logout: () => 'logout',
	};

	const inputCommand = (evt) => {
		const { code } = evt;
		if (code === 'Enter') {
			runCommand();
		}
	};
	const runCommand = () => {
		const tokens = command.trim().split(' ');
		const head = tokens.shift();
		const param = tokens.join(' ').trim();
		const action = actionMapping[head](param);
		dispatch('command', {action, param});
	};

	const commandMapping = {
		home: 'go /',
		parent: 'go ..',
		id: 'qi ',
		title: 'qn ',
		search: 'qs ',
		tag: 'qt ',
		edit: 'ed',
		overwrite: 'ow',
		discard: 'dc',
		taglist: 'tl',
		sublist: 'sl',
		newsub: 'ns',
		move: 'mv <32>',
		destroy: 'rm <21>',
		store: 'st',
		reload: 're',
	};

	const pasteCommand = async (cmd) => {
		if (commandMapping[cmd]) {
			command = commandMapping[cmd];
			await tick();
			document.querySelector('#cmd-inp').focus();
		}
	}
</script>

<div class="cmd-box">
	<input type="text" id="cmd-inp" bind:value={command} on:keydown={inputCommand}>
	<button on:click={() => runCommand()}><i class="bi bi-play"></i> play</button>
	<div>
		go
		<button on:click={() => pasteCommand('home')}><i class="bi bi-house"></i> home</button>
		{#if note}
			<button on:click={() => pasteCommand('parent')}><i class="bi bi-arrow-up-square"></i> parent</button>
		{/if}
	</div>
	<div>
		query
		<button on:click={() => pasteCommand('id')}><i class="bi bi-123"></i> id</button>
		<button on:click={() => pasteCommand('title')}><i class="bi bi-type"></i> title</button>
		<button on:click={() => pasteCommand('search')}><i class="bi bi-search"></i> search</button>
		<button on:click={() => pasteCommand('tag')}><i class="bi bi-tag"></i> tag</button>
	</div>
	{#if note}
		<div>
			{#if edit_content}
				<button on:click={() => pasteCommand('overwrite')}><i class="bi bi-check-square"></i> overwrite</button>
				<button on:click={() => pasteCommand('discard')}><i class="bi bi-x-square"></i> discard</button>
			{:else}
				<button on:click={() => pasteCommand('edit')}><i class="bi bi-pencil"></i> edit</button>
			{/if}
		</div>
		<div>
			view
			<button on:click={() => pasteCommand('taglist')}><i class="bi bi-tags"></i> tags</button>
			<button on:click={() => pasteCommand('sublist')}><i class="bi bi-list-ul"></i> subnotes</button>
		</div>
		<div>
			<button on:click={() => pasteCommand('newsub')}><i class="bi bi-file-earmark"></i> new.sub</button>
			<button on:click={() => pasteCommand('move')}><i class="bi bi-box-arrow-in-up-right"></i> move</button>
			<button on:click={() => pasteCommand('destroy')}><i class="bi bi-trash"></i> destroy</button>
		</div>
		{#if modified}
			<div>
				<span>modified</span>
				<button on:click={() => pasteCommand('store')}><i class="bi bi-cloud-upload"></i> store</button>
				<button on:click={() => pasteCommand('reload')}><i class="bi bi-arrow-counterclockwise"></i> reload</button>
			</div>
		{/if}
	{/if}
</div>

<style>
	.cmd-box {
		/* width: 60%; */
		min-width: 360px;
		/* margin: 0 auto; */
		/* background-color: #000; */
		/* */
		position: fixed;
		top: 1.5em;
		right: 0.5em; /*
		*/
		/*
		left: 50%;
		transform: translate(-50%, 0);
		*/
		background-color: #000;
		border: 1px dotted #888;
		padding: 0.5em;
		box-sizing: border-box;
	}
	.cmd-box div {
		margin-top: 0.5em;
	}
</style>
