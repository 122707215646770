<script>
import {createEventDispatcher} from 'svelte';

import TagItem from './TagItem.svelte';

const dispatch = createEventDispatcher();

export let tags;

let new_tag = '';

const addTag = () => {
	const tag = new_tag.trim();
	if (tag) {
		tags.push({tag});
		tags = tags;
		new_tag = '';
	}
}
const deleteTag = (i) => {
	tags.splice(i, 1);
	tags = tags;
};
const findTagged = (i) => {
	console.log('findTagged', i);
	const action = 'tag';
	const param = tags[i].tag;
	dispatch('command', {action, param});
};
const close = () => dispatch('close');
</script>

<div id="taglist">
	<div class="clearfix">
		<button class="close" on:click={close}>Close <i class="bi bi-x-square resizefont"></i></button>
	</div>
	<form on:submit|preventDefault={addTag}>
		<input type="text" bind:value={new_tag}>
		<button disabled={!new_tag}><i class="bi bi-plus-square"></i> Add</button>
	</form>
	{#each tags as tag, index}
		<TagItem {tag} on:delete={() => deleteTag(index)} on:tagged={() => findTagged(index)} />
	{/each}
</div>

<style>
#taglist {
	position: absolute;
	width: 40%;
	min-width: 360px; /* 60em; */ /* 600px */
	max-height: 60%;
	bottom: 3rem;
	left: 50%;
	transform: translate(-50%, 0);
	/* margin-left: auto; margin-right: auto; */

	background-color: #000;
	border: 1px dotted #888;
	padding: 0.5em;
}
input {
	width: 12rem;
}
</style>
