<script>
	import {createEventDispatcher, tick} from 'svelte';

	import {blurOnKey} from './util';

	const dispatch = createEventDispatcher();

	export let tag;

	let edit_mode = false;
	let input;

	$: url_by_tag =
		tag.tag ? `/?tag=${tag.tag.replaceAll(' ', '_')}` :
		'/';

	const editTag = async () => {
		edit_mode = true;
		await tick();
		input.focus();
	};
	const viewTag = () => edit_mode = false;
	const onTagChanged = () => {
		dispatch('change', tag.tag);
	};
	const destroy = () => {
		dispatch('delete');
	};
	const tagged = () => {
		dispatch('tagged');
	};

	
</script>

<div class="clearfix">
	{#if edit_mode}
		<input type="text" bind:this={input} bind:value={tag.tag} on:keydown={blurOnKey} on:blur={viewTag} on:change={onTagChanged}>
	{:else}
		<span on:click={editTag}>{ tag.tag }</span>
	{/if}
	<button on:click={destroy}><i class="bi bi-dash-square"></i> Delete</button>
	<!--
	<button class="right" on:click={tagged}><i class="bi bi-tag"></i> Notes</button>
	-->
	<a href={url_by_tag} class="btn r bi bi-tag" rel="noreferrer" target="_blank">Notes</a>
</div>

<style>
	input {
		width: 12rem;
	}
	span {
		display: inline-block;
	}
	/*
	button.right {
		float: right;
	}
	*/
	a.btn {
		border: 1px solid;
	}
	a.r {
		float: right;
	}
</style>
