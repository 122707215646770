<script>
	import {onMount} from 'svelte';

	import Login from './Login.svelte';
	import Note from './Note.svelte';
	import {apiKeyStore} from './store';
	
	let opt = {};
	let login = false;
	const afterLogin = () => {
		login = true;
	};
	const afterLogout = () => {
		login = false;
	};

	onMount(() => {
		if ($apiKeyStore) {
			login = true;
		}
		location.search.slice(1).split('&').forEach((qs) => {
			const a = qs.split('=');
			a[0] && (opt[a[0]] = decodeURIComponent(a[1]).replaceAll('_', ' '));
		});
	});
</script>

{#if login}
	<Note on:logout={afterLogout} {opt} />
{:else}
	<Login on:login={afterLogin} />
{/if}

<style>
	/*
	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}
	*/
</style>