<script>
	import {createEventDispatcher, onMount} from 'svelte';
	import {apiKeyStore} from './store';

	const dispatch = createEventDispatcher();
	let apiKey = '';

	const login = () => {
		if (apiKey) {
			apiKeyStore.setApiKey(apiKey);
			dispatch('login');
		}
	}

	const inputLogin = (evt) => {
		const { code } = evt;
		if (code === 'Enter') {
			const dlg = document.querySelector('#login-dialog');
			dlg.close('confirm');
		}
	};

	onMount(() => {
		const dlg = document.querySelector('#login-dialog');
		if (typeof dlg.showModal === 'function') {
			dlg.addEventListener('close', () => {
				(dlg.returnValue === 'confirm') && login();
			});
			dlg.showModal();
		} else {
			const _apiKey = prompt('API Key');
			apiKey = _apiKey.trim();
			login();
		}
	});
</script>

<div>
	<dialog id="login-dialog">
		<form method="dialog">
			<div>Login</div>
			<div>
				<input type="text" bind:value={apiKey} on:keydown={inputLogin}>
			</div>
			<div>
				<button value="cancel">Cancel</button>
				<button value="confirm">Confirm</button>
			</div>
		</form>
	</dialog>

</div>

<style>
	dialog {
		background-color: #000;
		color: #ccc;
		font-size: 0.9rem; /* 14.4px*/
	}
	dialog div ~ div {
		padding-top: 0.5em;
	}
</style>
