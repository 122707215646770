import {writable} from 'svelte/store';

import {loadApiKey, storeApiKey, clearApiKey} from './storage';

function initApiKeyStore() {
	const apiKey = loadApiKey() || '';
	const store = writable(apiKey);
	store.subscribe((value) => (value ? storeApiKey(value) : clearApiKey()));
	return {
		subscribe: store.subscribe,
		//getApiKey: () => $store,
		setApiKey: (newApiKey) => store.set(newApiKey)
	};
}

export const apiKeyStore = initApiKeyStore();
