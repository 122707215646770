
const deepCopy = (x) => JSON.parse(JSON.stringify(x));
const toString = (x) => JSON.stringify(x);
const jsonify = toString;
const jsonize = toString;

function zip(list1, list2) {
	if (list1 && list2 && list1.length && list2.length && list1.length === list2.length) {
		// arrow function has no arguments (BUT closure's)
		let lists = Array.from(arguments); // [].slice.call(arguments);
		lists.shift(); // list1
		return list1.map((x, ix) => {
			let zipped = [x];
			lists.forEach((list) => {
				zipped.push(list[ix]);
			});
			return zipped;
		})
	} else {
		return [];
	}
};

export const lost = {
	deepCopy,
	toString, jsonify, jsonize,
	zip,
};

export function blurOnKey(event) {
	const { code } = event;
	if (['Enter','Escape','Tab'].includes(code)) {
		event.target.blur();
	}
}
