<script>
	import {createEventDispatcher, onMount, tick} from 'svelte';

	import CommandBox from './CommandBox.svelte';
	import {apiKeyStore} from './store';
	import {idlenote} from './idlenote';
	import {interprete} from './interprete';
	import {lost, blurOnKey} from './util';
    import TagList from './TagList.svelte';
	import SubnoteList from './SubnoteList.svelte';

	const dispatch = createEventDispatcher();

	export let opt;

	let head_title = 'Loading...';
	let processing = false;
	let show_menu = false;
	
	let note_0;
	let note;
	let sublist = false;
	let notelist = false;

	let edit_title = false;
	let edit_content = false;
	let show_sublist = false;
	let show_taglist = false;

	$: title =
		note ? note.title :
		notelist ? '' :
		'';
	$: content_raw =
		note ? note.content :
		'';
	$: content_rendered =
		note && note.content ? interprete(note.content) :
		'&lt;EMPTY&gt;';
	$: modified = note && (
		(!note.id && note.title) ||
		note.title != note_0.title ||
		note.content != note_0.content ||
		note.tags.length != note_0.tags.length ||
		((tags_1, tags_0) => {
			const tags = lost.zip(tags_1, tags_0);
			return tags.some((t) => t[0].id != t[1].id || t[0].tag != t[1].tag);
		})(note.tags, note_0.tags)
	);

	const backupNote = () => {
		note_0 = lost.deepCopy(note);
	};
	const restoreNote = () => {
		note = lost.deepCopy(note_0);
	};
	const resetWithNote = async (data) => {
		note = lost.deepCopy(data);
		note.tags = note.tags || [];
		backupNote();
		sublist = false;
		notelist = false;
		head_title = note.id;
		resetDisplay();
		await tick();
		attachIntLinkHandler();
	};
	const resetWithNotelist = (datalist, title) => {
		note = false;
		note_0 = false;
		sublist = false;
		notelist = datalist.map((data) => lost.deepCopy(data)).sort((a, b) => a.title >= b.title);
		head_title = title;
		resetDisplay();
	};
	const resetDisplay = () => {
		edit_title = false;
		edit_content = false;
		show_sublist = false;
		show_taglist = false;
	};
	const buildSubnoteList = (datalist) => {
		sublist = datalist.map((data) => lost.deepCopy(data)).sort((a, b) => a.title >= b.title);
	}

	onMount(() => {
		idlenote.init($apiKeyStore);
		if (opt.id) {
			findId(opt.id);
		} else if (opt.title) {
			findTitle(opt.title);
		} else if (opt.tag) {
			findTag(opt.tag);
		} else {
			getHome();
		}
	});

	const logout = () => {
		apiKeyStore.setApiKey('');
		dispatch('logout');
	}

	const getHome = async () => {
		processing = true;
		let data = await idlenote.home();
		processing = false;
		data && data.id && resetWithNote(data);
	};
	const getParent = async () => {
		processing = true;
		let data = await idlenote.read(note.pid);
		processing = false;
		data && data.id && resetWithNote(data);
	};
	const save = async () => {
		processing = true;
		let data = await idlenote.save(note);
		processing = false;
		console.log(lost.jsonify(data));
		data && data.id && resetWithNote(data);
	};
	const move = async (pid) => {
		note.pid = pid;
		save();
	};
	const destroy = async (id) => {
		if (Number(id) === note.id) {
			const pid = note.id;
			processing = true;
			let data = await idlenote.destroy(note);
			processing = false;
			console.log(lost.jsonify(data));
			findId(pid);
		}
	};
	const findId = async (id) => {
		processing = true;
		let data = await idlenote.read(id);
		processing = false;
		data && data.id && resetWithNote(data);
	};
	const findTitle = async (title) => {
		processing = true;
		let data = await idlenote.read(0, title);
		processing = false;
		data && data.msg === 'Not Found' && newSub(title);
		data && data.id && resetWithNote(data);
	};
	const findAny = async (search) => {
		processing = true;
		let data = await idlenote.find(search);
		data && resetWithNotelist(data, `Search "${search}"`);
	};
	const findTag = async (tag) => {
		processing = true;
		let data = await idlenote.find(null, tag);
		processing = false;
		data && resetWithNotelist(data, `Tag "${tag}"`);
	};
	const showTagList = () => {
		show_taglist = true;
		show_sublist = false;
	};
	const closeTagList = () => show_taglist = false;
	const showSubnoteList = async () => {
		if (!Array.isArray(sublist)) {
			processing = true;
			let data = await idlenote.subnote(note.id);
			processing = false;
			data && buildSubnoteList(data);
		}
		show_sublist = true;
		show_taglist = false;
	};
	const closeSubnoteList = async () => show_sublist = false;
	const newSub = async (title) => {
		note = {
			pid: note.id,
			title: title || '',
			content: '',
			tags: [],
		};
		head_title = 'Edit New';
		edit_content = true;
		editTitle();
	};

	const editTitle = async () => {
		edit_title = true;
		await tick();
		document.querySelector('#titl-edit').focus();
	};
	const viewTitle = () => note.title.trim() && (edit_title = false);
	const editContent = async () => {
		edit_content = true;
		await tick();
		document.querySelector('textarea.cont-edit').focus();
	};
	const updateContent = async () => {
		/*
		console.log('innerHTML', document.querySelector('.cont-edit').innerHTML);
		let content = document.querySelector('.cont-edit').innerHTML;
		content = content.
			replaceAll('<br></div>','</div>').
			replaceAll('</div><div>','\n').
			replace('</div>','').
			replace('<div>','');
		note.content = content;
		//*/
		note.content = document.querySelector('.cont-edit').value;
		edit_content = false;
		await tick();
		attachIntLinkHandler();
	};
	const discardContent = () => edit_content = false;

	const attachIntLinkHandler = () => {
		const links = Array.from(document.querySelectorAll('.cont-view a.int'));
		links.forEach((link) => {
			link.addEventListener('click', (evt) => {
				evt.preventDefault();
				evt.stopPropagation();
				findTitle(evt.target.dataset.title );
			});
		});
	};

	const actionMapping = {
		home: () => getHome,
		parent: () => getParent,
		id: () => findId,
		title: () => findTitle,
		search: () => findAny,
		tag: () => findTag,
		edit: () => editContent,
		update: () => updateContent,
		discard: () => discardContent,
		tags: () => showTagList,
		sub: () => showSubnoteList,
		newsub: () => newSub,
		move: () => move,
		destroy: () => destroy,
		store: () => save,
		reload: () => note.id ? restoreNote : getParent,
		logout: () => logout,
	};

	const onCommand = (evt) => {
		const {action, param} = evt.detail;
		actionMapping[action]()(param);
		show_menu && toggleMenu();
	};

	const toggleMenu = async () => {
		show_menu = !show_menu;
		if (show_menu) {
			await tick();
			document.querySelector('#cmd-inp').focus();
		}
	};

	document.addEventListener('keydown', (evt) => {
		if (evt.key === ',' && evt.ctrlKey) {
			toggleMenu();
		}
	});
</script>

<div class="w">
	<div class="h {show_menu ? 'ext' : ''}">
		<div class="clearfix titlebar">
			{#if modified}
				<span class="mod-indicator">*</span>
			{:else}
				<span class="mod-indicator">&nbsp;</span>
			{/if}
			<span class="h-title">{ head_title }</span>
			<span class="h-menu-btn"><button style="display: inline-block;" on:click={toggleMenu}>
				{#if show_menu}
					<i class="bi bi-x-lg"></i>
				{:else}
					<i class="bi bi-list"></i>
				{/if}
			</button></span>
		</div>
	</div>
		{#if show_menu}
			<CommandBox on:command={onCommand}
				{note}
				{edit_content}
				{modified}
			/>
		{/if}
	<div class="b">
		{#if note}
			<div class="title">
				{#if edit_title}
					<input type="text" id="titl-edit" bind:value={note.title} on:keydown={blurOnKey} on:blur={viewTitle}>
				{:else}
					<span on:click={editTitle}>{ note.title }</span>
				{/if}
			</div>
			{#if edit_content}
				<!--
				<div class="cont-edit" contenteditable="true">{ content_raw }</div>
				-->
				<textarea class="cont-edit resizefont">{ content_raw }</textarea>
			{:else}
				<div class="cont-view">{ @html content_rendered }</div>
			{/if}
		{:else if notelist}
			<ul>
			{#each notelist as {id, title}, index}
				<li><a href={`/?id=${id}`} class="int" on:click|preventDefault={() => findId(id)}>{ title }</a>
				<a href={`/?id=${id}`} class="bi bi-box-arrow-up-right" rel="noreferrer" target="_blank"></a></li>
			{/each}
			</ul>
		{/if}
	</div>
	<div class="t">
		{#if processing}
			<span class="progress spin"><span><i class="bi bi-arrow-repeat"></i></span></span>
		{:else}
			<span class="progress">&nbsp;</span>
		{/if}
		<!--
		error message
		-->
	</div>
	{#if show_taglist}
		<TagList bind:tags={note.tags} on:command={onCommand} on:close={closeTagList} />
	{/if}
	{#if show_sublist}
		<SubnoteList {sublist} on:command={onCommand} on:close={closeSubnoteList} />
	{/if}
</div>

<style>
	.titlebar {
		text-align: center;
	}
	.mod-indicator {
		/*
		display: inline-block;
		width: 0.5em;
		*/
		float: left;
		min-width: 1.25em;
	}
	.h-title {
		display: inline-block;
		width: 50%;
		font-size: 1.0rem;
	}
	.h-menu-btn {
		/*
		float: inline-end;
		*/
		float: right;
		min-width: 1.5em;
	}
	.title {
		height: 1.5rem;
	}
	.title input {
		width: 100%;
	}
	.title span {
		display: inline-block;
		width: 100%;
	}
	.cont-view {
		border-top: 1px dotted;
	}
	textarea.cont-edit {
		width: 99%;
		/* min-height: 39rem; */
		min-height: calc(100vh - 6rem);
		overflow-y: scroll;
		resize: none;
	}
	span.progress {
		/* border: 1px dotted; */
		display: inline-block;
		width: 1rem; height: 1rem;
	}
	.spin {
		animation-name: spin;
		animation-duration: 3s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
	}
	.spin > span {
		/* border: 1px dotted #0ff; */
		display: inline-block;
		padding-top: 1px;
	}
	@keyframes spin {
		0% { transform: rotate(0deg); }
		50% { transform: rotate(180deg); }
		100% { transform: rotate(360deg); }
	}
	.h {
		padding: 0.125rem 0.25rem;
	}
	.t {
		padding: 0.25rem 0.25rem 0.125rem;
	}
</style>
